import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
export const constantRouterMap: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/loadingPage'
  },
  {
    path: '/loadingPage',
    name: 'loadingPage',
    component: () => import('../views/loadingPage.vue')
  },
  {
    path: "/404",
    component: () => import("../views/features/404.vue"),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import("../views/features/404.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRouterMap
})

export default router
