import { removeToken } from '@/utils/user';
import { getSsoAuthUrl } from "@/api/userLogin";

// 登录状态失效
export const handleLogout = () => {
  let pathname = window.location.pathname
  const back = pathname.includes('loadingPage') || pathname == '/' ? '' : window.location.pathname
  const clientLoginUrl = `${window.location.origin}/loadingPage?back=${back}`
  removeToken()
  getSsoAuthUrl({ clientLoginUrl }).then(res => {
    let icode = res.code;
    let idata = res.data;
    if (icode == 200) {
      setTimeout(() => {
        window.location.href = idata
      }, 1500)
    }
  })
  // autologout().then(res => {
  //   let { code } = res
  //   if (code == 200) {
  //     removeToken()
  //     getSsoAuthUrl({ clientLoginUrl }).then(res => {
  //       let icode = res.code;
  //       let idata = res.data;
  //       if (icode == 200) {
  //         setTimeout(() => {
  //           window.location.href = idata
  //         }, 1500)
  //       }
  //     })
  //   }
  // })
}


