
import request from '@/utils/request'

//获取用户信息
export function gettUser(data) {
  return request({
    url: '/auth/ser/login/getRoleBySession',
    method: 'post',
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data: data
  })
}


// 获取登录地址
export function getSsoAuthUrl(query) {
  return request({
    url: `/auth/ser/sso/getSsoAuthUrl?clientLoginUrl=${query.clientLoginUrl}`,
    method: 'get'
  })
}

// ticket换token
export function doLoginByTicket(query) {
  return request({
    url: `/auth/ser/sso/doLoginByTicket?ticket=${query.ticket}`,
    method: 'get'
  })
}

// 登出 
export function autologout() {
  return request({
    url: '/auth/ser/sso/logout',
    method: 'get',
  })
}