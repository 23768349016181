import axios from 'axios'
import router from '../router'
import {
  ElMessage,
  ElMessageBox,
  ElNotification
} from 'element-plus'
import {
  getToken
} from '@/utils/user'
import Config from '@/utils/settings'
import {
  handleLogout
} from "@/utils/bylog"; // getToken from cookie
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_ENV_URL, // api 的 base_url
  timeout: Config.timeout // 请求超时时间
})

const envor = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'
// request拦截器
service.interceptors.request.use(
  config => {
    let url = config.url
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改 
    }
    config.headers['Content-Type'] = 'application/json'
    if (window.history.state.current) {
      if (url.includes('getSsoAuthUrl') || url.includes('doLoginByTicket') || url.includes('getRoleBySession') || url.includes('sso/logout')) {
        config.baseURL = envor ? process.env.VUE_APP_BASE_API1 : process.env.VUE_APP_ENV_URLB
      } else {
        config.baseURL = envor ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_ENV_URLA
      }
    } else {
      config.baseURL = envor ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_ENV_URLA
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    let code = response.code || response.data.code || response.status || response.data.status
    let message = response.data.messages || response.data.info || '接口请求失败'
    message = message == 'PERMISSION_DENIED' ? '请输入正确账号和密码' : code == 404 ? "未找到接口" : message
    if (code == -1) {
      ElMessageBox.alert('暂无权限访问，您可以联系管理员添加权限',
        '系统提示', {
        confirmButtonText: '退出',
        type: 'warning',
        showClose: false,
        closeOnClickModal: true,
        showCancelButton: false,
        callback: () => {
          handleLogout()
        }
      })
    } else if (code == 901 || code == -20 || code == 401) {
      // ElMessageBox.alert('登录已过期',
      //   '系统提示', {
      //   confirmButtonText: '退出',
      //   type: 'warning',
      //   showClose: false,
      //   closeOnClickModal: false,
      //   showCancelButton: false,
      //   callback: () => {
      //     handleLogout()
      //   }
      // })
      if (!response.config.url.includes('getSsoAuthUrl') && !response.config.url.includes('login/getRoleBySession')) {
        ElNotification.error('您的登录已失效，请重新登录')
      }
      handleLogout()
    } else if (code == 999) {
      ElMessage.error(message)
      router.replace('/404')
    } else if (code && code != 200) {
      ElMessage.error(message)
    } else {
      if (response.config.url == '/file/v1/wba/excel') {
        return response
      } else {
        return response.data
      }
    }
  },
  error => {
    let status = error.response?.status
    let message = error.response.data.messages || error.response.data.info || '接口请求失败'
    if (status == 901 || status == -20 || status == 401) {
      if (!error.response.config.url.includes('getSsoAuthUrl') && !error.response.config.url.includes('login/getRoleBySession')) {
        ElNotification.error('您的登录已失效，请重新登录')
      }
      handleLogout()
    } else if (status == 999) {
      ElMessage.error(message)
      router.replace('/404')
    } else {
      let message = status == 404 ? '未查询到接口' : '接口请求失败'
      ElNotification.error(message)
    }
    return Promise.reject(error)
  }
)
export default service