// 用户信息模块
const user = {
  state: {
    systemplist: [],
    topMenuList: [],
    systemName: '',
    defaultActive: '',
    topActive: "11-22",
    roluteHistory: [],
    ListMenu: [],
    isCollapse: false,
    loading: false,
    device: 'desktop',
    userInfo: {},
    contentHeight: 0,
    menuCodeBtn: [],//按钮权限
  },

  mutations: {
    // 获取系统菜单
    getMenuList: (state, systemplist) => {
      state.systemplist = systemplist
    },
    // 获取顶部菜单
    gettopMenuList: (state, topMenuList) => {
      state.topMenuList = topMenuList
    },
    // 获取侧边菜单
    getleftMenu: (state, leftMenu) => {
      state.leftMenu = leftMenu
    },
    // 获取系统名称
    getsystemName: (state, systemName) => {
      state.systemName = systemName
    },
    // 设置默认项
    setdefaultActive: (state, defaultActive) => {
      state.defaultActive = defaultActive
    },
    // 设置顶部默认项
    settopActive: (state, topActive) => {
      state.topActive = topActive
    },
    // 记录用户浏览页面
    saveRouter: (state, obj) => {
      // type 1 清空数据 2数据超过15 删除第一项 3 添加数据
      let arr = state.roluteHistory;
      arr.forEach(element => {
        element['activeIndex'] = -1
      });
      obj.routerInfo['activeIndex'] = -1

      let result = arr.findIndex(item => {

        return item.title == obj.routerInfo.title
      })

      if (result != -1) {
        arr[result]['activeIndex'] = result
        return
      } else {
        obj.routerInfo['activeIndex'] = 1
      }
      switch (obj.type) {
        case 1:
          state.roluteHistory = obj.routerInfo || []
          break;
        case 2:
          state.roluteHistory.shift(obj.routerInfo)
          break;
        case 3:

          state.roluteHistory.push(obj.routerInfo)
          break;

      }
    },
    isCollapse: (state, status) => {
      state.isCollapse = !status
    },
    setLoading: (state, status) => {
      state.loading = status
    },
    setDevice: (state, device) => {
      state.device = device
    },
    //用户信息
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo
    },
    // 获取页面内容高度
    setHeight: (state, contentHeight) => {
      state.contentHeight = contentHeight
    },
    // 获取菜单按钮级权限
    getMenuBtn: (state, menuCodeBtn) => {
      state.menuCodeBtn = menuCodeBtn
    },
  },

  actions: {
    changeMenuList({
      commit
    }, data) {
      commit('getMenuList', data)
    }
  }

}

export default user